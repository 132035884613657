<template>
  <div class='suppliers-requirements'>
    <div class="container">
      <h2 class="title">{{$t('documentsTitle')}}</h2>
      <div class="suppliers-requirements__items">
        <div class="suppliers-requirements__item">
          <div class="suppliers-requirements__icon">
            <img src="@/assets/img/icons/suppliers/4.svg" alt="icon">
          </div>
          <div class="suppliers-requirements__descr">{{$t('documentsText1')}}</div>
        </div>
        <div class="suppliers-requirements__item">
          <div class="suppliers-requirements__icon">
            <img src="@/assets/img/icons/suppliers/5.svg" alt="icon">
          </div>
          <div class="suppliers-requirements__descr">{{$t('documentsText2')}}</div>
        </div>
        <div class="suppliers-requirements__item">
          <div class="suppliers-requirements__icon">
            <img src="@/assets/img/icons/suppliers/6.svg" alt="icon">
          </div>
          <div class="suppliers-requirements__descr">{{$t('documentsText3')}}</div>
        </div>
        <div class="suppliers-requirements__item">
          <div class="suppliers-requirements__icon">
            <img src="@/assets/img/icons/suppliers/7.svg" alt="icon">
          </div>
          <div class="suppliers-requirements__descr">{{$t('documentsText4')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SuppliersRequirements'
  }
</script>